import {gql} from "@apollo/client";

export const GetAllItemsGraphql = gql`
    query GetAllItems {
        allItems {
            readableId
            name
            fileUrl
        }
    }
`