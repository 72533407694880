import React, {FC} from "react";
import {GetItemGql, Item} from "../../graphql/GetItem.graphql";
import {useQuery} from "@apollo/client";
import {Avatar, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export const ItemCard: FC<{ item: Item }> = ({item}) => {

    return (
        <div>
            <Typography variant="h5" component="h2" style={{marginBottom: "10px"}}>Item Info: <Link to={`/items/${item.readableId}/edit`}>Edit</Link></Typography>
            <img
                src={item.fileUrl}
                alt={item.name}
                style={{width: "100%", maxWidth: "500px", borderRadius: "8px", marginBottom: "20px"}}
            />
            <Typography variant="h5" component="h2" style={{marginBottom: "10px"}}>
                {item.name}
            </Typography>
            <Link to={`/items/${item.readableId}`}>
                <Typography variant="body1" color="textSecondary" style={{marginBottom: "2px"}}>
                    ID: {item.readableId}
                </Typography>
            </Link>
            {item.storedItemsShortInfo && item.storedItemsShortInfo.length > 0 ?
                (
                <div>
                        <Typography variant="body1" color="textSecondary" style={{marginBottom: "2px"}}>
                            Items in this box:
                        </Typography>
                        <ul>
                            {item.storedItemsShortInfo.map((item, index) => (
                                <li key={index}>
                                    {item.readableId} - <Link to={`/items/${item.readableId}`}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )

                : <div/>
            }
            {/*<Typography variant="body1" color="textSecondary">*/}
            {/*    Type: {item.isBox ? 'Box' : 'Individual Item'}*/}
            {/*</Typography>*/}
            {/* Additional details or actions can be placed here */}
        </div>
    );
};
