import {FC} from "react";
import {useParams} from "react-router-dom";
import {ItemCard} from "./ItemCard";
import {useQuery} from "@apollo/client";
import {GetItemGql, Item} from "../../graphql/GetItem.graphql";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {BoxSection} from "./BoxSection";

export const ItemInfoPage: FC = () => {
    const {readableId} = useParams<{ readableId: string }>();
    const {loading, error, data} = useQuery<{
        item: Item
    }>(GetItemGql, {variables: {readableId}});

    if (!readableId) {
        return <p>No readableId</p>
    }
    if (loading) {
        return <p>Loading...</p>
    }
    if (error) {
        return <p>Error: {error.message}</p>
    }
    if (!data) {
        return <p>No data</p>
    }

    return (
        // <ItemInfo readableId={readableId}/>
        <Grid container={true} spacing={2}>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <ItemCard item={data.item}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <BoxSection item={data.item}/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
        ;
}