import React, {FC, useState} from "react";
import {Item} from "../../graphql/GetItem.graphql";
import {ItemCard} from "./ItemCard";
import {Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {EditBoxSection} from "./EditBoxSection";

export const BoxSection: FC<{ item: Item }> = ({item}) => {
    const [editMode, setEditMode] = useState(false);

    const [readableId, setReadableId] = useState(item.box?.readableId || "")

    const box = item.box

    return (
        <div>
            {editMode
                ?
                <EditBoxSection item={item} setEditMode={setEditMode}/>
                : <div>
                {
                    box ?
                        <div>
                            <Typography variant="h5" component="h2" style={{marginBottom: "10px"}}>Stored
                                in:</Typography>
                            <Button variant="contained"
                                    onClick={() => setEditMode(!editMode)}>{editMode ? "Done" : "Edit"}</Button>
                            <ItemCard item={box}/>
                        </div>
                        :
                        <div>
                            <Typography variant="h5" component="h2" style={{marginBottom: "10px"}}>Stored
                                in:</Typography>
                            <Button variant="contained"
                                    onClick={() => setEditMode(!editMode)}>{editMode ? "Done" : "Edit"}</Button>
                            <Typography variant="body1" color="textSecondary" style={{marginBottom: "2px"}}>This item is
                                not
                                stored in a box.</Typography>
                            <Link to={"/create-box"}>Create a box</Link>
                        </div>
                } </div>
            }


        </div>
    )
}