import React, {FormEvent, FormEventHandler, useState} from "react";
import {ALGOLIA_API_KEY, ALGOLIA_APP_ID} from "../../Constants";
import algoliasearch from "algoliasearch/lite";
import {useQuery} from "@apollo/client";
import {GetItemGql, Item} from "../../graphql/GetItem.graphql";
import {GetItemsForSearchGql} from "../../graphql/GetItems.graphql";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {SearchResultItemComponent} from "./SearchResultItemComponent";

export const SearchComponent = () => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<any[]>([]);
    const searchClient = algoliasearch(
        ALGOLIA_APP_ID,
        ALGOLIA_API_KEY
    )

    const [searchPerformed, setSearchPerformed] = useState(false);

    // we'll fetch additional details from graphql backend
    // and display them in the search results
    const {data, loading, error} = useQuery<{ items: Item[] }>(GetItemsForSearchGql, {
        variables: {readableIds: results.map((item) => item.readableId)},
        skip: !results.length,
    });

    const {data: oneItemData, loading: oneItemLoading, error: oneItemError} = useQuery(GetItemGql, {
        variables: {readableId: "00001"},
        // when search is done we need to set searchPerformed to true
        // so that we can show the search results
        onCompleted: () => setSearchPerformed(true),
    });


    const handleSearch = async (e: FormEvent) => {
        // prevent default
        e.preventDefault();
        const index = searchClient.initIndex("bardaak_items");
        const {hits} = await index.search(query);
        setResults(hits);
    };

    return (
        <div>
            <form onSubmit={handleSearch} style={{display: "flex", alignItems: "center", gap: "8px"}}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    fullWidth
                />
                <Button variant="contained" color="primary" type="submit">
                    Search
                </Button>
            </form>
            {/*// search results*/}

            {/*    If searchPerformed is true: show error if there are no items found, otherwise show findings */}
            {/*if it's loading, show Loading...*/}
            {/*if it's error, show Error: <error message>*/}
            {/*if it's data, show <number of items found> items found*/}
            {/*if there are items, show them*/}
            {/*if there are no items, show No items found*/}

            <div>
                {loading && <div>Loading...</div>}
                {error && <div>Error: {error.message}</div>}
            </div>
            <div>
                {
                    searchPerformed && !data?.items.length
                        ? <div>No items found</div>
                        : <div>
                            <p>{data?.items.length} items found </p>
                        <div>
                            {
                                data?.items.map((item) => (                                    <SearchResultItemComponent item={item} key={item.id}/>))
                            }</div>
                        </div>

                }
            </div>
        </div>
    );
};

export default SearchComponent;
