import React, {FC} from "react";
import { Card, CardActionArea, CardContent, CardMedia, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {Item} from "../../graphql/GetItem.graphql";

export const ItemCard: FC<{ item: Item }> = ({ item }) => {

    return (
        <Card>
            <CardActionArea component={RouterLink} to={`/items/${item.readableId}`}>
                <CardMedia
                    component="img"
                    image={item.fileUrl || "https://via.placeholder.com/600x337?text=No+Image"} // 16:9 aspect ratio placeholder
                    alt={item.name}
                    style={{ height: '400px', width: '100%', objectFit: 'cover' }} // Adjust the height as needed
                />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {item.name}
                    </Typography>
                    <Divider light />
                    <Typography color="textSecondary">
                        ID: {item.readableId}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
