import React, {FC, useState} from "react";
import {GoogleComponent} from "../GoogleComponent/GoogleComponent";

export function FailAuthPage() {

    // header "Bardaak"
    // form to login with Google log-in button

    return (
        <div>
            <h1>Failed to get access</h1>
            <h2>Try to relogin with google</h2>

            <GoogleComponent/>
        </div>
    )

}