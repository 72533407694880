import {Item} from "../../graphql/GetItem.graphql";
import {FC} from "react";
import { Card, CardActionArea, CardMedia, CardContent, Typography, Link } from '@mui/material';

export const SearchResultItemComponent: FC<{ item: Item }> = ({ item }) => {
    // Assuming you have a route in your application that handles URLs in the format of `/item/${item.readableId}`
    const itemUrl = `/items/${item.readableId}`;

    return (
        <Link href={itemUrl} style={{ textDecoration: 'none' }}>
            <Card sx={{ display: 'flex', marginBottom: 2 }}>
                <CardActionArea sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                    {item.fileUrl && (
                        <CardMedia
                            component="img"
                            sx={{ width: 151, height: 151, objectFit: 'cover' }}
                            image={item.fileUrl}
                            alt={item.name}
                        />
                    )}
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h5" component="div">
                            {item.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {item.readableId}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    );
};
