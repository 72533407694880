import {gql} from "@apollo/client";

export  const GetItemsForSearchGql = gql`
    query GetItemsForSearch($readableIds: [String!]!) {
        items(readableIds: $readableIds) {
            readableId
            name
            fileUrl
        }
    }
`
