import React, {FC, useState} from "react";

export function AppMainPage() {

    // header "Bardaak"
    // form to login with Google log-in button

    return (
        <div>
            <h1>Bardaak</h1>
            <h2>Log in with Google and enjoy</h2>
        </div>
    )

}