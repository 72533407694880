import {gql} from "@apollo/client";

export  const GetItemGql = gql`
    query GetItem($readableId: String!) {
        item(readableId: $readableId) {
            readableId
            name
            fileUrl
            box {
                name
                fileUrl
                readableId
                id
            }
            isBox
            storedItems
            storedItemsShortInfo {
                readableId
                name
                fileUrl
            }
        }
    }
`
export interface Item {
    id: string;
    readableId: string;
    name: string;
    fileUrl?: string;
    isBox: boolean;
    boxId?: string;
    box?: Item;
    storedItems?: string[];
    storedItemsShortInfo: Item[];
}
