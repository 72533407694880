import {gql} from "@apollo/client";

export  const EditItemGql = gql`
    mutation EditItem($readableId: String!, $name: String!, $fileUrl: String) {
        editItem(readableId: $readableId, name: $name, fileUrl: $fileUrl) {
            readableId
            name
            fileUrl
        }
    }
`