import {API_BASE_URL} from "../Constants";

export async function uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem("token");

    const uploadResponse = await fetch(`${API_BASE_URL}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (!uploadResponse.ok) {
        throw new Error('File upload failed');
    }

     // Assuming the response is just the URL text
    return await uploadResponse.text();
}