export function resizeImage(file: File, maxWidth: number, callback: (file: File) => void) {
    console.log("resizeImage")
    const reader = new FileReader();
    reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                return;
            }

            // Calculate the new image dimensions
            let width = img.width;
            let height = img.height;
            if (width > height && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            } else if (height > maxWidth) {
                width *= maxWidth / height;
                height = maxWidth;
            }

            // Set the canvas to the new dimensions
            canvas.width = width;
            canvas.height = height;

            console.log("Resizing to " + width + "x" + height)

            ctx.drawImage(img, 0, 0, width, height);

            // Convert canvas to blob
            canvas.toBlob((blob) => {
                if (!blob) {
                    return;
                }
                // Convert blob to file
                const resizedFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: Date.now(),
                });
                callback(resizedFile);
            }, file.type);
        };
        if (typeof event.target?.result === "string") {
            img.src = event.target.result;
        }
    };
    reader.readAsDataURL(file);
}


export async function resizeImageAsync(file: File, maxWidth: number) {
    return new Promise<File>((resolve, reject) => {
        resizeImage(file, maxWidth, (resizedFile) => {
            resolve(resizedFile);
        });
    });
}