import {GetItemGql, Item} from "../../graphql/GetItem.graphql";
import {FC, useState} from "react";
import {useMutation} from "@apollo/client";
import {AssignItemToBoxGql} from "../../graphql/AssignItemToBox.graphql";
import {useNavigate} from "react-router-dom";

export const EditBoxSection: FC<{ item: Item, setEditMode: (editMode: boolean) => void }> = ({item, setEditMode}) => {

    const [readableId, setReadableId] = useState(item.boxId || "")

    const [assignItemToBox, {
        data: mutationData,
        loading: mutationLoading,
        error: mutationError
    }] = useMutation(AssignItemToBoxGql,
        {
            refetchQueries: [GetItemGql]
        }
        );
    const navigate = useNavigate()

    const assignBox = () => {
        assignItemToBox({
            variables: {
                itemId: item.readableId,
                boxId: readableId
            },
            onCompleted: () => {
                navigate(`/items/${item.readableId}`)
            }
        })
        setEditMode(false)
    }

    return (
        <div>
            <h1>Edit Box Section</h1>
            <input type="text" value={readableId} onChange={e => setReadableId(e.target.value)}/>
            <button onClick={assignBox}>Done</button>
            <button onClick={() => setEditMode(false)}>Cancel</button>
        </div>
    )
}