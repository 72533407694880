
/*
                <Input placeholder="Readable ID" onChange={e => setReadableId(e.target.value)}/>
                <Button color="inherit" onClick={handleGo}>
                    Go
                </Button>

 */
import React, {FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Input} from "@mui/material";
import Button from "@mui/material/Button";
import {useQuery} from "@apollo/client";
import {GetItemGql} from "../graphql/GetItem.graphql";

export function GoToPage() {
    const [readableId, setReadableId] = useState<string>("");
    const navigate = useNavigate();

    const {data: oneItemData, loading: oneItemLoading, error: oneItemError} = useQuery(GetItemGql, {
        variables: {readableId: "00001"}
    });

    const handleGo = (e: FormEvent) => {
        e.preventDefault()
        console.log(readableId);
        navigate(`/items/${readableId}`)
    }

    return (
        <form onSubmit={(e) => handleGo(e)}>
            <Input placeholder="Readable ID" onChange={e => setReadableId(e.target.value)}/>
            <Button type="submit" color="inherit">
                Go
            </Button>
        </form>
    )
}
