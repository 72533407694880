import React, {useState} from "react";
import {Card, CardContent, Typography, TextField, Button, IconButton, CircularProgress} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {styled} from "@mui/material/styles";
import {useMutation, useQuery} from "@apollo/client";
import {CreateItemGql} from "../../graphql/CreateNewItem.graphql";
import {useNavigate, useParams} from "react-router-dom";
import {API_BASE_URL} from "../../Constants";
import {uploadFile} from "../../helpers/UploadHelpers";
import {resizeImage, resizeImageAsync} from "../../helpers/ImageHelpers";
import {UploadFile} from "@mui/icons-material";
import {GetItemGql, Item} from "../../graphql/GetItem.graphql";
import {EditItemGql} from "../../graphql/EditItem";

const Input = styled("input")({
    display: "none",
});

export const EditItemPage: React.FC = () => {

    const {readableId} = useParams<{ readableId: string }>();
    const {data, loading, error} = useQuery<{item: Item}>(GetItemGql, {
        variables: {
            readableId
        },
        onCompleted: (item) => {
            setName(data?.item.name);
            setImagePreviewUrl(data?.item.fileUrl);
        }
    })


    const [name, setName] = useState(data?.item.name);
    const [file, setFile] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(data?.item.fileUrl);
    const [editItem, {
        data: mutationData,
        loading: mutationLoading,
        error: mutationError
    }] = useMutation(EditItemGql);
    const navigate = useNavigate();


    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] ?? null;
        setFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSubmit = async () => {
        if (name) {
            try {
                const fileUrl = file ? await uploadFile(await resizeImageAsync(file, 1200)) : null;

                // Step 2: Create the item with the file URL
                editItem({
                    variables: {
                        readableId,
                        name,
                        fileUrl
                    },
                    onCompleted: (data) => {
                        navigate(`/items/${data.editItem.readableId}`);
                    },
                    refetchQueries: [ GetItemGql ]

                });
            } catch (error) {
                console.error("Error during file upload or item creation:", error);
                // Handle error appropriately
            }
        }
    };


    // Your existing return statement
    // ...

    // Handle loading state for the mutation

    if (loading) return <CircularProgress/>;
    if (error) return <Typography color="error">Error loading item! {error.message}</Typography>;


    if (mutationLoading) return <CircularProgress/>;

    // Handle error state for the mutation
    if (mutationError) return <Typography color="error">Submission error! {mutationError.message}</Typography>;

    // Optionally, handle the completed state
    if (mutationData) return <Typography>Item created successfully!</Typography>;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Edit Item {data?.item ? JSON.stringify(data?.item) : "bad"}</Typography>
                <TextField
                    fullWidth
                    label="Item Name"
                    value={name}
                    onChange={handleNameChange}
                    margin="normal"
                />
                <label htmlFor="icon-button-takephoto">
                    <Input
                        accept="image/*"
                        id="icon-button-takephoto"
                        type="file"
                        capture="environment" // Use 'user' for the front camera on mobile devices
                        onChange={handleCapture}
                    />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera/>
                    </IconButton>
                </label>

                <label htmlFor="icon-button-uploadphoto">
                    <Input
                        accept="image/*"
                        id="icon-button-uploadphoto"
                        type="file"
                        // capture="environment" // Use 'user' for the front camera on mobile devices
                        onChange={handleCapture}
                    />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <UploadFile/>
                    </IconButton>
                </label>
                {/*checkbox for isBox */}
                {/*<label htmlFor="isBox">*/}
                {/*    <Input*/}
                {/*        id="isBox"*/}
                {/*        type="checkbox"*/}
                {/*        checked={isBox}*/}
                {/*        onChange={() => setIsBox(!isBox)}*/}
                {/*    />*/}
                {/*    <Typography>Is Box</Typography>*/}
                {/*</label>*/}


                {file && <Typography>{file.name}</Typography>}
                {imagePreviewUrl &&
                    <img src={imagePreviewUrl} alt="Preview" style={{maxWidth: "100%", height: "auto"}}/>}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{marginTop: "20px"}}
                >
                    Submit
                </Button>
            </CardContent>
        </Card>
    );
};
