import {GoogleLogin} from "@react-oauth/google";
import React from "react";
import {useApolloClient} from "@apollo/client";
import {useNavigate} from "react-router-dom";

export function GoogleComponent() {
    const apolloClient = useApolloClient()
    const router = useNavigate()
    return (
        <GoogleLogin
            onSuccess={credentialResponse => {
                localStorage.setItem("token", credentialResponse.credential as string);
                apolloClient.refetchQueries(
                    {
                        include: "all"
                    }
                )
                router("/search")

            }}
            onError={() => {
                console.log("Login Failed");
            }}
            useOneTap={true}
            auto_select={true}
        />
    )
}