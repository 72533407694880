import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function LogOutPage() {
    const router = useNavigate();

  useEffect(() => {
      localStorage.removeItem("token");
      // and go to main page
        router("/");
  });

  return (
    <div>
      <h1>Logged Out</h1>
    </div>
  );
}