import {gql} from "@apollo/client";

export const CreateItemGql = gql`
    mutation createItem($name: String!, $fileUrl: String, $isBox: Boolean!) {
        createItem(name: $name, fileUrl: $fileUrl, isBox: $isBox) {
            name
            readableId
            fileUrl
        }
    }
`