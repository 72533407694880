import React, {FC} from "react";
import {GetAllItemsGraphql} from "../../graphql/GetAllItems.graphql";
import {Item} from "../../graphql/GetItem.graphql";
import {useQuery} from "@apollo/client";
import {
    CircularProgress,
    Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ItemCard} from "./ItemCard";
export const ItemsList: FC = () => {
    const { loading, error, data } = useQuery<{ allItems: Item[] }>(GetAllItemsGraphql);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;
    if (!data) return <Typography>No data</Typography>;

    return (
        <div>
            <Typography variant="h4" gutterBottom>Items:</Typography>
            <Grid container spacing={2}>
                {data.allItems.map(item => (
                    <Grid item xs={12} sm={6} md={4} key={item.readableId}>
                        <ItemCard item={item} /> {/* Use the ItemCard component */}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
