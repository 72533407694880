import {gql} from "@apollo/client";

export  const AssignItemToBoxGql = gql`
    mutation AssignItemToBox($itemId: String!, $boxId: String!) {
        assignItemToBox(itemId: $itemId, boxId: $boxId) {
            readableId
            name
            fileUrl
            boxId
            isBox
        }
    }
`