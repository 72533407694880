import React, {FC} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Input} from "@mui/material";
import {GoogleLogin} from "@react-oauth/google";
import {GoogleComponent} from "./GoogleComponent/GoogleComponent";

export const AppHeader: FC = () => {
    const navigate = useNavigate()


    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    My App
                </Typography>
                {/*New item*/}
                <Button color="inherit" component={RouterLink} to={"/goto"}>
                    Go
                </Button>
                <Button color="inherit" component={RouterLink} to="/search">
                    Search
                </Button>

                <Button color="inherit" component={RouterLink} to="/items/new">
                    New Item
                </Button>
                <Button color="inherit" component={RouterLink} to="/items">
                    Items
                </Button>
                {localStorage.getItem("token")
                    ?
                    <Button color="inherit" component={RouterLink} to="/logout">
                        Logout
                    </Button>
                    :
                    <GoogleComponent/>
                }
            </Toolbar>
        </AppBar>
    );
};
