import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, ServerError} from "@apollo/client";
import {ItemsList} from "./components/ItemsList/ItemsList";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ItemInfoPage} from "./components/ItemInfo/ItemInfoPage";
import {CreateItemPage} from "./components/CreateItemPage/CreateItemPage";
import {API_BASE_URL} from "./Constants";
import {EditItemPage} from "./components/EditItemPage/EditItemPage";
import {setContext} from "@apollo/client/link/context";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {LogOutPage} from "./components/LogOutPage/LogOutPage";
import {GoToPage} from "./components/GoToPage";
import {SearchComponent} from "./components/SearchComponent/SearchComponent";
import {AppMainPage} from "./components/AppMainPage/AppMainPage";
import {onError} from "@apollo/client/link/error";
import {FailAuthPage} from "./components/FailAuthPage/FailAuthPage";

const httpLink = createHttpLink({
    uri: `${API_BASE_URL}/graphql`,
})
const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({message, locations, path}) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
    // if it's 401, perform a logout and go to login page
    // check for ts type of error
    // because it has following declaration: networkError?: Error | ServerError | ServerParseError;
    if (networkError && (networkError as ServerError).statusCode === 401) {
        localStorage.removeItem("token");
        window.location.href = "/fail-auth";
    }

}
)
const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
})
const client = new ApolloClient({
    // uri: "http://localhost:8080/graphql",
    cache: new InMemoryCache(),
    link: errorLink.concat(authLink).concat(httpLink)
})

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <App/>,
            children:
                [
                    // define something for main page
                    {path: "/", element: <AppMainPage/>},
                    {path: "/logout", element: <LogOutPage/>},
                    {path: "/fail-auth", element: <FailAuthPage/>},
                    {path: "/goto", element: <GoToPage/>},
                    {path: "/items", element: <ItemsList/>},
                    {path: "/items/new", element: <CreateItemPage/>},
                    {path: "/items/:readableId", element: <ItemInfoPage/>},
                    {path: "/items/:readableId/edit", element: <EditItemPage/>},
                    {path: "/search", element: <SearchComponent/>}


                ]
        },
    ]
)




const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <GoogleOAuthProvider clientId="474269076589-clg7na0pgtp3pv5ag4ipvp9dq63ufm0c.apps.googleusercontent.com">
                <RouterProvider router={router}/>
            </GoogleOAuthProvider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
// Bringing in the GoogleOAuthProvider from the package
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="PUT-YOUR-CLIENT-ID-HERE">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);
 */