import React from 'react';
import './App.css';
import {AppHeader} from "./components/AppHeader";
import {Outlet} from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import {ALGOLIA_API_KEY, ALGOLIA_APP_ID} from "./Constants";

function App() {
    return (
      <div>
      <AppHeader/>
      <Outlet/>
        </div>
  );
}

export default App;